import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from '../../../common/components/text';
import HeaderStyles from './header_styles';
import { getWordpressUrl } from '../../wp';
import { getShopifyUrl } from '../../shopify';
import { getQualtricsUrl } from '../../qualtrics';
import MenuIcon from '../../../common/components/icon/menu_icon';
import NavDrawer from '../nav_drawer';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func,
};

const defaultProps = {
  toggleDrawer: () => {},
};

const HeaderView = ({ signedIn, toggleDrawer }) => (
  <HeaderStyles>
    <a href={getWordpressUrl('/')}>
      <img id="logo" className="logo" src="../images/logo.svg" alt="Somn" />
    </a>

    <NavDrawer>
      <nav className="header-nav" data-mobile-open="true">
        <Link href={getQualtricsUrl()}>Assessment</Link>
        <a href={getWordpressUrl('/knowledge')}>Knowledge</a>
        <a href={getWordpressUrl('/about-us')}>About Us</a>
        {/* <a href={getShopifyUrl()}>Solutions</a> */}
        {(signedIn && <a href="/dashboard">My Account</a>) || <Link to="/signin">Sign In</Link>}
        {/* {(signedIn && <Link to="/signout">Sign Out</Link>) || <Link to="/signin">Sign In</Link>} */}
      </nav>
    </NavDrawer>

    <div role="presentation" className="menu-toggle" onClick={() => toggleDrawer()}>
      <MenuIcon />
    </div>
  </HeaderStyles>
);

HeaderView.propTypes = propTypes;
HeaderView.defaultProps = defaultProps;

export default HeaderView;
