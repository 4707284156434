import React from 'react';
import PropTypes from 'prop-types';
import SliderStyles from './slider_styles';

const propTypes = {
  percent: PropTypes.number,
  label: PropTypes.string,
};

const defaultProps = {
  percent: 0,
  label: 'You',
};

const SliderView = ({ percent, label }) => (
  <SliderStyles>
    <div className="sliderGraph">
      <div className="sliderGraphEl" style={{ left: `${percent <= 0 ? 0 : percent >= 100 ? 100 : percent}%` }}>
        {/* {label} */}
        {percent <= 33 ? 'Low' : percent <= 66 ? 'Mid': 'High'}
      </div>
    </div>
  </SliderStyles>
);

SliderView.propTypes = propTypes;
SliderView.defaultProps = defaultProps;

export default SliderView;
