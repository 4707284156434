import React from 'react';
import PropTypes from 'prop-types';
import FullReportStyles from './full_report_styles';
import Block from '../../../../common/components/block';
import Button from '../../../../common/components/button';
import { Text, TextTitle, TextHeading, TextSubheading } from '../../../../common/components/text';
import { FactorGraph, FactorHeader, FactorIcon } from '../factors';
import theme from '../../../../common/theme';
import Slider from '../../slider';
import Copy, { getFactorCopy } from '../../../copy';
import { getWordpressUrl } from '../../../wp';
import ResponseModel from '../../../models/response_model';

const FullReportView = ({ user, response }) => {
  const res = response && typeof response !== 'ResponseModel' ? new ResponseModel(response) : response; // eslint-disable-line valid-typeof
  if (localStorage.getItem('responseId') && localStorage.getItem('responseId') !== response.id) {
    localStorage.setItem('responseId', response.id);
  }
  if (!res) return (<div />);

  const dateOptions = { year: 'numeric', 'month': 'long', day: 'numeric' }
  const date = new Date(res.createdAt)
  
  return (
    <FullReportStyles>
      <div>
        <Block className="wrapper-intro">
          <div className="row">
            <div className="col-md-8">
              <TextTitle color={theme.color.text.light} margin="none">
                {user && user.firstName}’s Sleep Report
              </TextTitle>
              <Text size="medium" color={theme.color.secondary.lighter}>
                {(res.createdAt && `
                  From the assessment you took on ${date.toLocaleDateString('en-US', dateOptions)}
                `)}
              </Text>
            </div>
          </div>
        </Block>

        <Block className="wrapper-overview">
          <TextHeading size="medium">Sleep Data Overview</TextHeading>

          <div className="overview-grid">

            <div className="overview">
              <header className="overview-header">
                <TextHeading size="small" textcase="uppercase">
                  Your Sleep Health
                </TextHeading>
                <Slider percent={res.score('nighttimeSleepScore')} />
              </header>

              <Text size="small" color={theme.color.primary.dark}>
                {getFactorCopy('nighttimeSleep', res.score('nighttimeSleepScore'))}
              </Text>
            </div>

            <div className="overview">
              <header className="overview-header">
                <TextHeading size="small" textcase="uppercase">
                  Your daytime performance
                </TextHeading>
                <Slider percent={res.score('daytimeFunctionScore')} />
              </header>
              <Text size="small" color={theme.color.primary.dark}>
                {getFactorCopy('daytimeFunction', res.score('daytimeFunctionScore'))}
              </Text>
            </div>
          </div>
        </Block>

        <Block className="wrapper-sleep-factors">

          <TextHeading size="large">
            The Sleep Factors
            <TextSubheading color={theme.color.neutral.dark}>
              Let’s get to the root cause of your sleep struggles so you can find relevant solutions that actually make a difference.
            </TextSubheading>
          </TextHeading>

          <div className="sleep-factor sleep-factor-mind" key="factor-mind" data-factor="mind">
            <header className="sleep-factor-header">
              <FactorIcon factor="mind" className="factor-icon" />
              <div className="factor-name">
                <FactorHeader factor="mind" className="factor-header" />
                <Text color={theme.color.text.light}>Anxieties • Worries • Feelings</Text>
              </div>
            </header>

            <div className="sleep-factor-results">
              <div className="sleep-factor-sidebar">
                <a target="_blank" rel="noopener noreferrer" href={getWordpressUrl('/knowledge/resources/factor-mind/')}>
                  <FactorGraph percent={res.score('weightedMindScore')} id="mind" />
                  <br />
                  <Text size="small">{getFactorCopy('mind', res.score('weightedMindScore'))}</Text>
                </a>
              </div>

              <div className="sleep-factor-details">
                <TextHeading size="medium">How This is Assessed?</TextHeading>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['mind', 'anxiety', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['mind', 'anxiety', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('anxietyScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['mind', 'stress', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['mind', 'stress', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('stressScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['mind', 'negativeMood', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['mind', 'negativeMood', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('negativeMoodScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['mind', 'overactiveMind', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['mind', 'overactiveMind', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('overactiveMindScore')} />
                </div>
              </div>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-body" key="factor-body" data-factor="body">
            <header className="sleep-factor-header">
              <FactorIcon factor="body" className="factor-icon" />
              <div className="factor-name">
                <FactorHeader factor="body" className="factor-header" />
                <Text color={theme.color.text.light}>Biology • Discomfort • Physiology</Text>
              </div>
            </header>

            <div className="sleep-factor-results">
              <div className="sleep-factor-sidebar">
                <a target="_blank" rel="noopener noreferrer" href={getWordpressUrl('/knowledge/resources/factor-body/')}>
                  <FactorGraph percent={res.score('weightedBodyScore')} id="body" />
                  <br />
                  <Text size="small">{getFactorCopy('body', res.score('weightedBodyScore'))}</Text>
                </a>
              </div>

              <div className="sleep-factor-details">
                <TextHeading size="medium">How This is Assessed?</TextHeading>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['body', 'apnea', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['body', 'apnea', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('apneaScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['body', 'restlessLegs', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['body', 'restlessLegs', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('restlessLegScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['body', 'pain', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['body', 'pain', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('painScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['body', 'physicalDiscomfort', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['body', 'physicalDiscomfort', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('physicalDiscomfortScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['body', 'menopause', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['body', 'menopause', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('menopauseScore')} />
                </div>
              </div>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-social" key="factor-social" data-factor="social">
            <header className="sleep-factor-header">
              <FactorIcon factor="social" className="factor-icon" />
              <div className="factor-name">
                <FactorHeader factor="social" className="factor-header" />
                <Text color={theme.color.text.light}>External Pressures • Obligations • Relationships</Text>
              </div>
            </header>

            <div className="sleep-factor-results">
              <div className="sleep-factor-sidebar">
                <a target="_blank" rel="noopener noreferrer" href={getWordpressUrl('/knowledge/resources/factor-social/')}>
                  <FactorGraph percent={res.score('weightedSocialScore')} id="social" />
                  <br />
                  <Text size="small">{getFactorCopy('social', res.score('weightedSocialScore'))}</Text>
                </a>
              </div>

              <div className="sleep-factor-details">
                <TextHeading size="medium">How This is Assessed?</TextHeading>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'childScore', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'childScore', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('childrenScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'pregnant', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'pregnant', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('pregnantScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'workShift', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'workShift', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('workScore')} />
                </div>

                {/* <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'childDisrupts', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'childDisrupts', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={response.score('childrenScore')} />
                </div> */}

                {/* <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'workDisrupts', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'workDisrupts', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={response.score('workScore')} />
                </div> */}

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'bedPartnerDisrupts', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'bedPartnerDisrupts', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('bedPartnerScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['social', 'petDisrupts', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['social', 'petDisrupts', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('petScore')} />
                </div>
              </div>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-routine" key="factor-routine" data-factor="routine">
            <header className="sleep-factor-header">
              <FactorIcon factor="routine" className="factor-icon" />
              <div className="factor-name">
                <FactorHeader factor="routine" className="factor-header" />
                <Text color={theme.color.text.light}>Personal Habits • Behaviors • Choices</Text>
              </div>
            </header>

            <div className="sleep-factor-results">
              <div className="sleep-factor-sidebar">
                <a target="_blank" rel="noopener noreferrer" href={getWordpressUrl('/knowledge/resources/factor-routine/')}>
                  <FactorGraph percent={res.score('weightedRoutineScore')} id="routine" />
                  <br />
                  <Text size="small">{getFactorCopy('routine', res.score('weightedRoutineScore'))}</Text>
                </a>
              </div>

              <div className="sleep-factor-details">
                <TextHeading size="medium">How This is Assessed?</TextHeading>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['routine', 'bedtime', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['routine', 'bedtime', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('bedtimeScore')} />
                </div>

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['routine', 'exercise', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['routine', 'exercise', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('exerciseScore')} />
                </div>

                {/* <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['routine', 'caffiene', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['routine', 'caffiene', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={response.score('caffieneScore')} />
                </div> */}

                <div className="sleep-factor-detail">
                  <div className="inline">
                    <Text size="small" weight="bold">
                      {Copy.getIn(['routine', 'wouldChange', 'title'])}
                    </Text>
                    <span className="spacer" />
                    <Text size="small" color={theme.color.neutral.darker}>{Copy.getIn(['routine', 'wouldChange', 'subtitle'])}</Text>
                  </div>
                  <Slider percent={res.score('desrieForChangeScore')} />
                </div>
              </div>
            </div>
          </div>

          <div className="sleep-factor sleep-factor-environment" key="factor-environment" data-factor="environment">
            <header className="sleep-factor-header">
              <FactorIcon factor="environment" className="factor-icon" />
              <div className="factor-name">
                <FactorHeader factor="environment" className="factor-header" />
                <Text color={theme.color.text.light}>Noise • Light • Interior Layout</Text>
              </div>
            </header>

            {/* TODO : Determine if we display this section, there are no scores */}
            <div className="sleep-factor-results">
              <div className="sleep-factor-sidebar">
                <a target="_blank" rel="noopener noreferrer" href={getWordpressUrl('/knowledge/resources/factor-environment/')}>
                  <img className="factor-graph" src="/images/gauge-0.svg" alt="needs assessment" />
                  <div className="factor-text">
                    <Text weight="bold" size="small">Needs Assessment</Text>
                  </div>
                </a>
              </div>

              <div className="sleep-factor-details">
                <div className="button-container">
                  <Button as="a" buttonType="disabled" href="#noop">
                    Coming Soon
                  </Button>
                </div>

                <TextHeading size="medium" className="learn-more">
                  Learn more about the<br />
                  <Button as="a" buttonType="text" href={getWordpressUrl('/knowledge/resources/factor-environment/')}>Environment Sleep Factor</Button>
                </TextHeading>
              </div>
            </div>
          </div>
        </Block>
      </div>
    </FullReportStyles>
  );
};

FullReportView.propTypes = {
  user: PropTypes.shape({ firstName: PropTypes.string }).isRequired,
  results: PropTypes.shape({ getIn: PropTypes.func }).isRequired,
  response: PropTypes.shape({}).isRequired,
};

export default FullReportView;
