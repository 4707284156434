import React from 'react';
import DashboardHomeStyles from './dashboard_home_styles';
import Link from '../../../common/components/link';
import { Text, TextTitle, TextHeading } from '../../../common/components/text';
import { FactorGraph, FactorHeader, FactorIcon } from '../reports/factors';
import Product from '../product';
import Button from '../../../common/components/button';
import Slider from '../slider';
import { getWordpressUrl } from '../../wp';

const DashboardHomeView = ({ user, products, posts, response }) => (
  <DashboardHomeStyles>
    <section className="wrapper-intro">
      <div className="row">
        <div className="col-md-8">
          <TextTitle>
            Welcome{user.day === 1 ? '' : ' back'},
            <big>{user && user.firstName}</big>
          </TextTitle>
        </div>
        <div className="col-md-4" style={{ textAlign: 'center' }}>
          <TextHeading size="medium">Wondering if your sleep has improved?</TextHeading>
          <p>
            <Button as="a" href="https://rndresearchteam.qualtrics.com/jfe/form/SV_40HEXC8ht8xJtB3">
              {response ? 'Retake' : 'Take'} the assessment
            </Button>
          </p>
          <Text size="small">
            <p>Track changes over time by taking the assessment more than once.</p>
          </Text>
        </div>
      </div>
    </section>

    {response && (
      <section className="wrapper-overview">
        <TextHeading size="medium">Sleep Data Overview</TextHeading>

        <div className="overview-items">
          <div className="overview-item">
            <TextHeading size="small" textcase="uppercase">
              Your Sleep Health
            </TextHeading>
            <Slider percent={response.score('nighttimeSleepScore')} />
          </div>
          <div className="overview-item">
            <TextHeading size="small" textcase="uppercase">
              Your daytime performance
            </TextHeading>
            <Slider percent={response.score('daytimeFunctionScore')} />
          </div>
        </div>
      </section>
    )}

    {response && (
      <section className="wrapper-sleep-factors">
        <TextHeading size="medium">The Sleep Factors</TextHeading>

        <div className="factors-grid">
          <div className="factors-grid-item" key="factor-mind" data-factor="mind">
            <a className="factors-grid-item-header" href={getWordpressUrl('/factor-mind')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="mind" />
                <FactorIcon factor="mind" className="factor-icon" />
              </div>
            </a>
            <div className="factors-grid-item-result">
              <div className="gauge-wrapper">
                <FactorGraph percent={response.score('weightedMindScore')} id="mind" />
              </div>
            </div>
          </div>

          <div className="factors-grid-item" key="factor-body" data-factor="body">
            <a className="factors-grid-item-header" href={getWordpressUrl('/factor-body')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="body" />
                <FactorIcon factor="body" className="factor-icon" />
              </div>
            </a>
            <div className="factors-grid-item-result">
              <div className="gauge-wrapper">
                <FactorGraph percent={response.score('weightedBodyScore')} id="body" />
                {console.log(response.score('weightedBodyScore'))}
              </div>
            </div>
          </div>

          <div className="factors-grid-item" key="factor-social" data-factor="social">
            <a className="factors-grid-item-header" href={getWordpressUrl('/factor-social')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="social" />
                <FactorIcon factor="social" className="factor-icon" />
              </div>
            </a>
            <div className="factors-grid-item-result">
              <div className="gauge-wrapper">
                <FactorGraph percent={response.score('weightedSocialScore')} id="social" />
              </div>
            </div>
          </div>

          <div className="factors-grid-item" key="factor-routine" data-factor="routine">
            <a className="factors-grid-item-header" href={getWordpressUrl('/factor-routine')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="routine" />
                <FactorIcon factor="routine" className="factor-icon" />
              </div>
            </a>
            <div className="factors-grid-item-result">
              <div className="gauge-wrapper">
                <FactorGraph percent={response.score('weightedRoutineScore')} id="routine" />
              </div>
            </div>
          </div>

          <div className="factors-grid-item" key="factor-environment" data-factor="environment">
            <a className="factors-grid-item-header" href={getWordpressUrl('/factor-environment')}>
              <div className="factors-grid-item-header-inner">
                <FactorHeader factor="environment" />
                <FactorIcon factor="environment" className="factor-icon" />
              </div>
            </a>
            <div className="factors-grid-item-result">
              <div className="gauge-wrapper">
                Not Assessed
                <br />
                <Link href="/">Find Out More</Link>
              </div>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button as="a" href={`/dashboard/reports/${response.id}`} buttonType="primary">
            View Full Report
          </Button>
        </div>
      </section>
    )}

    <section className="wrapper-recommended-reading">
      <TextHeading size="medium">{user && user.firstName}’s Bedtime Reading</TextHeading>

      <div className="blog-grid">
        {posts &&
          posts.map(({ imageUrl, id, excerpt, link, title }) => (
            <a className="blog-post" target="_blank" rel="noopener noreferrer" href={link} key={id}>
              <div className="blog-thumb">
                <img src={imageUrl} alt={title} />
              </div>
              <TextHeading size="medium" margin="none">
                {title}
              </TextHeading>
              <Text size="small">
                <p dangerouslySetInnerHTML={{ __html: excerpt }} />
              </Text>
            </a>
          ))}
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button as="a" href={getWordpressUrl('/posts')} buttonType="primary">
          See All Posts
        </Button>
      </div>
    </section>

    <section className="wrapper-recommended-solutions">
      <TextHeading size="medium">Recommendations For You</TextHeading>

      <div className="product-grid">
        {products.size > 0 &&
          products
            .slice(0, 3)
            .map(({ id, title, description, percentMatch, price, featuredImageUrl, factor, productAddUrl, productUrl }) => (
              <Product
                factor={factor}
                image={featuredImageUrl}
                key={id}
                productMatch={percentMatch}
                productDescription={description}
                productPrice={`$${price}`}
                productTitle={title}
                productAddUrl={productAddUrl}
                productUrl={productUrl}
              />
            ))}
      </div>
    </section>
  </DashboardHomeStyles>
);

export default DashboardHomeView;
