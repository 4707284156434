import styled, { css } from 'styled-components';
import theme from '../../../common/theme';
import device from '../../../common/device';

const NavDrawerStyles = styled.div`

  @media ${device.onlySmall} {
    position: absolute;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
    padding: 1rem;
  }

  ${(props) => props.show === 'hide' && css`
    @media ${device.onlySmall} {
      opacity: 0;
      height: 0vh;
      transition: height 240ms ease-in 240ms;
      pointer-events: none;

      a {
        opacity: 0;
        transition: opacity 340ms ease-in;
      }
    }
  `};

  ${(props) => props.show === 'show' && css`
    @media ${device.onlySmall} {
      opacity: 1;
      height: 100vh;
      transition: height 240ms ease-in;

      a {
        opacity: 1;
        transition: opacity 340ms ease-in 240ms;
      }
    }
  `};
`;

export default NavDrawerStyles;
