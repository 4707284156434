import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Block from '../../../common/components/block';
import { Text, TextTitle } from '../../../common/components/text';
import Link from '../../../common/components/link';
import Product from '../product';
import ContactForm from '../contact_form';
import DashboardProductsStyles from './dashboard_products_styles';

const propTypes = {
  user: PropTypes.shape({ signedIn: PropTypes.bool.isRequired }).isRequired,
  products: PropTypes.instanceOf(List),
};

const EMPTY_LIST = new List();

const defaultProps = {
  products: EMPTY_LIST,
};

const DashboardProductsView = ({ user, products }) => (
  <DashboardProductsStyles>
    <Block className="wrapper-intro">
      <div className="row">
        <div className="col-md-6">
          <TextTitle margin="none">
            Sleep Solutions
            <br />
            for {user && user.firstName}
          </TextTitle>
        </div>

        <div className="col-md-6">
          <Text>
            Good to see you, {user && user.firstName}. Take a look around. And, if you need help with anything don’t hesitate{' '}
            <Link href="mailto:info@somn.co" target="_window">
              to ask
            </Link>
            .
          </Text>
        </div>
      </div>
    </Block>

    <Block className="wrapper-recommended-solutions">
      <div className="product-grid">
        {
          products.size > 0 && products.map((product) => {
            // const evenProducts = products.size % 3 === 0;
            // const lastProduct = products.size === index + 1;
            // const component;
            // if (evenProducts || !lastProduct) {
            const component = (
              <Product
                factor={product.factor}
                image={product.featuredImageUrl}
                key={product.id}
                productAddUrl={product.productAddUrl}
                productMatch={product.percentMatch}
                productDescription={product.description}
                productPrice={`$${product.price}`}
                productTitle={product.title}
                productUrl={product.productUrl}
              />
            );
            // }
            return component;
          })
        }
        {/* { products.size % 3 !== 0 && <ContactForm /> } */}
        { <div className="product-item"><ContactForm /></div> }
      </div>
    </Block>
  </DashboardProductsStyles>
);


DashboardProductsView.propTypes = propTypes;
DashboardProductsView.defaultProps = defaultProps;

export default DashboardProductsView;
