import styled from 'styled-components';
import AssessmentResultStyles from '../assessment_result/assessment_result_styles';
import ProductStyles from '../product/product_styles';
import theme from '../../../common/theme';
import device from '../../../common/device';

const DashboardHomeStyles = styled(AssessmentResultStyles)`
  background-color: ${theme.color.white};

  .dashboard-container {
    width: calc(100vw - 250px);
  }

  .sleep-factors {
    flex-direction: column;

    .sleep-factor {
      width: 100%;
    }
  }

  [class^='wrapper-'] {
    padding: 2rem 1.25rem;
    position: relative;

    @media ${device.medium} {
      padding: 3rem 2rem;
    }
  }

  .wrapper-intro {
    &:before {
      background-color: transparent;
      background-image: url('images/bg-dashboard-home.png');
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }

    &:after {
      background-image: url('images/bg-box-tan.png');
      background-position: left bottom 1vw;
      background-repeat: no-repeat;
      background-size: 105%;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 35%;
      z-index: 0;
    }

    .row {
      position: relative;
      z-index: 1;
    }
  }

  big {
    display: block;
    font-size: 60px;

    @media ${device.medium} {
      font-size: 70px;
    }
  }

  .wrapper-overview {
    .overview-items {
      @media ${device.medium} {
        display: flex;
        flex-direction: row;
      }
    }

    .overview-item {

      @media ${device.medium} {
        width: 50%;
        margin: 0 2vw;
      }

      &:first-of-type {
        margin-bottom: 3rem;

        @media ${device.medium} {
          margin-bottom: 0;
          margin-left: 0;
        }
      }

      &:last-of-type {
        @media ${device.medium} {
          margin-right: 0;
        }
      }
    }
  }

  .factors-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 2rem;

    @media ${device.large} {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .factors-grid-item {
    background-color: ${theme.color.neutral.light};
    display: flex;
    flex-direction: column;
    width: calc(50% - 0.5rem);
    margin: 0 0.5rem 1rem;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(even) {
      margin-right: 0;
    }

    @media ${device.medium} {
      margin-bottom: 1rem;
      width: calc((100% / 3) - (0.5rem * 4 / 3));

      &:nth-of-type(odd) {
        margin-left: 0.5rem ;
      }

      &:nth-of-type(even) {
        margin-right: 0.5rem ;
      }

      &:nth-of-type(3n-2) {
        margin-left: 0;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    @media ${device.large} {
      margin-bottom: 0;
      width: calc(20% - (0.5rem * 8 / 5));

      &:nth-of-type(3n-2) {
        margin-left: 0.5rem;
      }

      &:nth-of-type(3n) {
        margin-right: 0.5rem;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .factors-grid-item-header {
    display: block;
    width: 100%;
    height: 0;
    padding: 0.5rem 0.5rem calc(62.5% - 0.5rem);
    position: relative;

    &:hover {
      opacity: 0.85;
    }

    .factor-icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      height: calc(100% - 1rem);
      width: auto;
    }

    svg {
      width: auto;
      height: 11vw;

      @media ${device.medium} {
        width: auto;
        height: 4vw;
      }
    }
  }

  [data-factor='mind'] .factors-grid-item-header {
    background-color: ${theme.color.factor.mind};
  }

  [data-factor='body'] .factors-grid-item-header {
    background-color: ${theme.color.factor.body};
  }

  [data-factor='social'] .factors-grid-item-header {
    background-color: ${theme.color.factor.social};
  }

  [data-factor='routine'] .factors-grid-item-header {
    background-color: ${theme.color.factor.routine};
  }

  [data-factor='environment'] .factors-grid-item-header {
    background-color: ${theme.color.factor.environment};

    .factors-grid-item-header-inner {
      svg:not(.factor-icon) {
        @media ${device.large} {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .factors-grid-item-result {
    flex-direction: column;
    font-family: ${theme.font.serif.bold};
    font-size: 1.5vw;
    height: 0;
    padding: 1vw 1vw 50%;
    position: relative;

    .gauge-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0.5rem;
      font-size: 15px;

      @media ${device.medium} {
        padding: 0;
      }
    }
  }

  .wrapper-recommended-reading {
    box-shadow: inset 0 1px 0 ${theme.color.forest.lightest}, inset 0 -1px 0 ${theme.color.forest.lightest};

    .blog-grid {

      @media ${device.medium} {
        display: flex;
        flex-direction: row;
      }
    }

    .blog-thumb {
      display: block;
      height: 0;
      margin-bottom: 1em;
      overflow: hidden;
      padding-bottom: 50%;
      position: relative;
      width: 100%;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.color.primary.dark};
        z-index: 1;
        mix-blend-mode: exclusion;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${theme.color.neutral.light};
        z-index: 1;
        mix-blend-mode: color-burn;
      }

      img {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .blog-post {
      color: ${theme.color.text.dark};
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      margin: 0 0 1rem;
      text-decoration: none;
      width: 100%;

      @media ${device.medium} {
        margin: 0 1rem 1rem;
        width: calc(100% / 3 - (1rem * 4 / 3));
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 2px 0 ${theme.color.action};
      }
    }
  }

  .product-grid {
    padding: 0;

    @media ${device.medium} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  ${ProductStyles} {
    margin: 0 0 2rem;
    width: 100%;

    @media ${device.medium} {
      margin: 0 0.5rem 2rem;
      width: calc(50% - (0.5rem));

      &:nth-of-type(odd) {
        margin-left: 0;
      }

      &:nth-of-type(even) {
        margin-right: 0;
      }
    }

    @media ${device.large} {
      margin: 0 0.5rem 2rem;
      width: calc(33% - (2rem / 3));

      &:nth-of-type(odd) {
        margin-left: 0.5rem;
      }

      &:nth-of-type(even) {
        margin-right: 0.5rem;
      }

      &:nth-of-type(3n - 2) {
        margin-left: 0;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
`;

export default DashboardHomeStyles;
