import styled from 'styled-components';
import theme from '../../../../../common/theme';
import device from '../../../../../common/device';

const FactorGraphStyles = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .factor-text {
    bottom: 0.25vw;
    font-family: ${theme.font.serif.bold};
    line-height: 1;
    position: absolute;
    text-align: center;
    width: 100%;

    @media ${device.medium} {
      font-size: 1.5vw;
    }
  }
`;

export default FactorGraphStyles;
