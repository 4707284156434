import { connect } from 'react-redux';
import AssessmentResultView from './assessment_result_view';
import { getResponse, getCurrentUser, getProducts } from '../../../reducer';
import { fetchResponse, fetchProducts } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  return {
    response: getResponse(state, id),
    user: getCurrentUser(state),
    products: getProducts(state),
    signedIn: getCurrentUser(state).signedIn,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id } = ownProps.match.params;
  if (localStorage.getItem('responseId') !== id) {
    localStorage.setItem('responseId', id);
  }
  return {
    fetchResponse: () => dispatch(fetchResponse(id)),
    fetchProducts: () => dispatch(fetchProducts(id)),
  };
};

const AssessmentResultContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentResultView);

export default AssessmentResultContainer;
