import { normalize } from 'normalizr';
import * as schema from './schema';
import * as RealBackend from './real_backend';
import * as MockBackend from './mock_backend';

let SelectedBackend;

if (process.env.REACT_APP_API_ENDPOINT === undefined) {
  console.log('----- USING MOCK BACKEND -----');
  SelectedBackend = MockBackend;
} else {
  SelectedBackend = RealBackend;
}

const normalizeResponses = (response) => normalize(response.data, [schema.archived]);
const normalizeResponse = (response) => normalize(response.data, schema.response);
const normalizePosts = (response) => normalize(response.data, [schema.post]);
const normalizeProducts = (response) => normalize(response.data, [schema.product]);

export const signIn = (email, password) => SelectedBackend.signIn(email, password);
export const finishSignIn = () => SelectedBackend.finishSignIn();
export const register = (info) => SelectedBackend.register(info);
export const confirmEmail = (token) => SelectedBackend.confirmEmail(token);
export const updateCurrentUser = (token, props) => SelectedBackend.updateCurrentUser(token, props);

export const forgotPassword = (email) => SelectedBackend.forgotPassword(email);
export const resetPassword = (token, password) => SelectedBackend.resetPassword(token, password);

export const fetchResponses = (token) => SelectedBackend.fetchResponses(token).then(normalizeResponses);
export const fetchResponse = (id, token) => SelectedBackend.fetchResponse(id, token).then(normalizeResponse);
export const fetchPosts = () => SelectedBackend.fetchPosts().then(normalizePosts);
export const fetchProducts = () => MockBackend.fetchProducts().then(normalizeProducts);
export const createMessage = (props) => SelectedBackend.createMessage(props).then(console.log('success'));

export const { OAUTH } = SelectedBackend;
