import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';
import { List } from 'immutable';
import DashboardStyles from './dashboard_styles';
import { getShopifyUrl } from '../../shopify';

// Import View components for Routes
import Sidebar from '../sidebar';
import DashboardHome from './dashboard_home_view';
import FullReport from '../reports/full_report';
import DashboardProductsView from './dashboard_products_view';
import Reports from '../reports';
import Profile from '../profile';

const propTypes = {
  user: PropTypes.shape({ signedIn: PropTypes.bool.isRequired }).isRequired,
  posts: PropTypes.instanceOf(List),
  products: PropTypes.instanceOf(List),
  fetchPosts: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchArchives: PropTypes.func.isRequired,
  fetchResponse: PropTypes.func.isRequired,
  response: PropTypes.shape({}).isRequired,
  archives: PropTypes.shape([]).isRequired,
};

const EMPTY_LIST = new List();

const defaultProps = {
  posts: EMPTY_LIST,
  products: EMPTY_LIST,
};

const DashboardView = ({
  archives,
  fetchArchives,
  fetchPosts,
  fetchProducts,
  fetchResponse,
  posts,
  products,
  response,
  responseId,
  user,
}) => {
  useEffect(() => {
    if (user.signedIn) fetchArchives(user.authToken);
  }, [user.signedIn, fetchArchives, user.authToken]);
  useEffect(() => {
    if (user.signedIn) fetchProducts();
  }, [user.signedIn, fetchProducts]);
  useEffect(() => {
    if (user.signedIn) fetchPosts();
  }, [user.signedIn, fetchPosts]);
  useEffect(() => {
    // this assigns the response to this user server side
    if (user.signedIn) fetchResponse(localStorage.getItem('responseId'), user.authToken);
  }, [user.signedIn, fetchResponse, user.authToken]);
  if (user && !user.signedIn) return <Redirect to="/signin" />;
  return (
    <DashboardStyles>
      <Sidebar />

      <div className="dashboard-container">
        <Switch>
          {/* <Route path="/dashboard/reports/:id" render={() => <FullReportWithLoader isLoading={!response} user={user} products={products} response={response} />} /> */}
          <Route path="/dashboard/reports/:id" render={(props) => <FullReport {...props} products={products} />} />
          <Route exact path="/dashboard/reports" render={() => <Reports user={user} products={products} archives={archives} />} />
          <Route exact path="/dashboard/products" render={() => <DashboardProductsView user={user} products={products} fetchProducts={fetchProducts} />} />
          <Route
            exact
            path="/dashboard/orders"
            component={() => {
              window.location.href = getShopifyUrl();
              return null;
            }}
          />
          <Route exact path="/dashboard/profile" component={Profile} />
          <Route exact path="/dashboard" render={() => <DashboardHome user={user} products={products} posts={posts} response={response} />} />
        </Switch>
      </div>
    </DashboardStyles>
  );
};

DashboardView.propTypes = propTypes;
DashboardView.defaultProps = defaultProps;

export default DashboardView;
