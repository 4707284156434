import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/components/button';
import Icon from '../../../common/components/icon';
import { Text, TextHeading } from '../../../common/components/text';
import ProductStyles from './product_styles';
import theme from '../../../common/theme';

const propTypes = {
  factor: PropTypes.string,
  image: PropTypes.string,
  productMatch: PropTypes.number,
  productAddUrl: PropTypes.string,
  productDescription: PropTypes.string,
  productPrice: PropTypes.string,
  productTitle: PropTypes.string,
  productUrl: PropTypes.string,
};

const defaultProps = {
  factor: undefined,
  image: undefined,
  productMatch: undefined,
  productAddUrl: undefined,
  productDescription: undefined,
  productPrice: '$0',
  productTitle: undefined,
  productUrl: undefined,
};

const ProductView = ({ factor, image, productMatch, productAddUrl, productDescription, productPrice, productTitle, productUrl }) => (
  <ProductStyles>
    <header className="product-header">
      <div className="product-match">
        <Text color={theme.color.text.light}>{productMatch}% Match to You</Text>
      </div>
      <div className="product-image" style={{ backgroundImage: `url(${image})` }} />
    </header>
    <div className="product-body">
      <TextHeading size="medium" margin="none">
        {productTitle}
      </TextHeading>
      <div className="product-meta">
        <Text size="large" color={theme.color.secondary.dark}>
          {productPrice}
        </Text>
      </div>
      <Text size="small" margin="bottom">
        {productDescription}
      </Text>

      <div className="product-cta">
        <Button as="a" href={productAddUrl} buttonType="secondary">
          Add to Cart
        </Button>
        <Button as="a" href={productUrl} buttonType="text">
          View Item
        </Button>
      </div>

      <footer className="product-footer">
        <p>Recommended for the following:</p>
        <div className="product-factor-grid">
          <div data-active={factor && factor.get('body')} className="product-factor factor-body">
            <Icon icon="body" />
          </div>
          <div data-active={factor && factor.get('social')} className="product-factor factor-social">
            <Icon icon="social" />
          </div>
          <div data-active={factor && factor.get('mind')} className="product-factor factor-mind">
            <Icon icon="mind" />
          </div>
          <div data-active={factor && factor.get('routine')} className="product-factor factor-routine">
            <Icon icon="routine" />
          </div>
        </div>
      </footer>
    </div>
  </ProductStyles>
);

ProductView.propTypes = propTypes;
ProductView.defaultProps = defaultProps;

export default ProductView;
